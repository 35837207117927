import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";

$(document).ready(function () {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(Draggable);
  gsap.registerPlugin(InertiaPlugin);

  Draggable.create("#dragme", {
    type: "y",
    edgeResistance: 0.65,
    bounds: "#drag-container",
    inertia: true,
  });

  var Cont = { val: 0 },
    NewVal = 100;

  const items = document.querySelectorAll(".countme");

  $(".counter").each(function () {
    var count = $(this),
      zero = { val: 0 };

    gsap.to(zero, {
      ease: Power4.easeOut,
      scrollTrigger: {
        trigger: ".work-results",
        markers: false,
      },
      val: count.data("number"),
      duration: 4,
      onUpdate: function () {
        count.text(zero.val.toFixed(1));
      },
    });
  });
});
